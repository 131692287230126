import Vue from 'vue'
import VueRouter from 'vue-router'

import store from './store/index.js'
import auth from './services/auth.service'
import { loadNamespace } from './i18n/index';

Vue.use(VueRouter);

// Layouts
import Layout from './components/Layout/LayoutContent';
import LayoutWallCenter from './components/Layout/LayoutWallCenter';

// Dashboard
const Dashboard = () => import ('./views/Dashboard.vue');

//Profile
const Settings = () => import ('./views/Profile/Settings.vue');
const Account = () => import ('./views/Profile/Settings/Account.vue');
const PublicProfile = () => import ('./views/Profile/Settings/PublicProfile.vue');
const Devices = () => import ('./views/Profile/Settings/Devices.vue');
const GoogleTwoFactorAuth = () => import ('./views/Profile/Settings/GoogleTwoFactorAuth.vue');
const Language = () => import ('./views/Profile/Settings/Language.vue');
const Notifications = () => import ('./views/Profile/Settings/Notifications.vue');
const Terms = () => import ('./views/Profile/Settings/Terms.vue');
const Verification = () => import ('./views/Profile/Settings/Verification.vue');
const Address = () => import ('./views/Profile/Settings/Address.vue');
const Sponsor = () => import ('./views/Profile/Settings/Sponsor.vue');


const PlantSettings = () => import ('./modules/Growing/views/Settings/Plants.vue');
const MarketSettings = () => import ('./modules/Growing/views/Settings/Market.vue');

//Public
const EnterGhostMode = () => import ('./views/Public/EnterGhostMode.vue');
const AuthWithToken = () => import ('./views/Public/AuthWithToken.vue');
const Login = () => import ('./views/Public/Login.vue');
const Register = () => import ('./views/Public/Register.vue');
const Recover = () => import ('./views/Public/Recover.vue');
const ResetPassword = () => import ('./views/Public/ResetPassword.vue');
const VerifiedConfirmation = () => import ('./views/Public/VerifiedConfirmation.vue');
const Lock = () => import ('./views/Public/Lock.vue');
const Routes = () => import ('./views/Public/Routes.vue');

const routes = [{
    path: '/',
    redirect: {name: 'Login'}
},
    // Admin Pages
    {
        path: '/',
        component: Layout,
        meta: {requiresAuth: true},
        children: [
            {
                path: 'dashboard',
                name: 'Dashboard',
                meta: {
                    requiresAuth: true,
                    modulesRequired: ['Wallet', 'Growing']
                },
                component: Dashboard
            },
            {
                path: 'settings',
                name: 'Settings',
                redirect: {name: 'SettingsAddress'},
                meta: {
                    requiresAuth: true
                },
                component: Settings,
                children: [
                    {
                        path: 'address',
                        name: 'SettingsAddress',
                        meta: {requiresAuth: true},
                        component: Address
                    },
                    {
                        path: 'account',
                        name: 'SettingsAccount',
                        meta: {requiresAuth: true},
                        component: Account
                    },
                    {
                        path: 'public-profile',
                        name: 'SettingsPublicProfile',
                        meta: {requiresAuth: true},
                        component: PublicProfile
                    },
                    {
                        path: 'verification',
                        name: 'SettingsVerification',
                        meta: {requiresAuth: true},
                        component: Verification
                    },
                    {
                        path: 'language',
                        name: 'SettingsLanguage',
                        meta: {requiresAuth: true},
                        component: Language
                    },
                    {
                        path: '2fa',
                        name: 'Settings2fa',
                        meta: {requiresAuth: true},
                        component: GoogleTwoFactorAuth
                    },
                    {
                        path: 'devices',
                        name: 'SettingsDevices',
                        meta: {requiresAuth: true},
                        component: Devices
                    },
                    {
                        path: 'notifications',
                        name: 'SettingsNotifications',
                        meta: {requiresAuth: true},
                        component: Notifications
                    },
                    {
                        path: 'terms',
                        name: 'SettingsTerms',
                        meta: {requiresAuth: true},
                        component: Terms
                    },
                    {
                        path: 'market',
                        name: 'Growing:SettingsMarket',
                        meta: {
                            requiresAuth: true,
                            modulesRequired: ['Wallet', 'Growing']
                        },
                        component: MarketSettings
                    },
                    {
                        path: 'plants',
                        name: 'Growing:SettingsPlants',
                        meta: {
                            requiresAuth: true,
                            modulesRequired: ['Growing']
                        },
                        component: PlantSettings
                    },
                    {
                        path: 'sponsor',
                        name: 'SettingsSponsor',
                        meta: {
                            requiresAuth: true
                        },
                        component: Sponsor
                    }
                ]
            },
        ]
    },
    // User Pages
    {
        path: '/',
        component: LayoutWallCenter,
        children: [
            {
                component: Login,
                name: 'Login',
                path: 'login',
                meta: {redirectDashboard: true},
            }, {
                component: Register,
                name: 'Register',
                path: 'register',
                meta: {redirectDashboard: true},
            }, {
                component: Recover,
                name: 'Recover',
                path: 'recover',
                meta: {redirectDashboard: true},
            }, {
                component: ResetPassword,
                name: 'ResetPassword',
                path: 'reset-password/:email/:token',
                meta: {redirectDashboard: true},
            }
        ]
    },
    // User Pages
    {
        path: '/',
        component: LayoutWallCenter,
        children: [
            {
                component: Lock,
                name: 'Lock',
                path: 'lock',
            },
            {
                component: VerifiedConfirmation,
                name: 'VerifiedConfirmation',
                path: 'email-verification/:status',
            }
        ]
    },
    //Public pages
    {
        path: '/enter-ghost-mode',
        name: 'EnterGhostMode',
        component: EnterGhostMode
    },
    {
        path: '/routes',
        name: 'Routes',
        component: Routes
    },
    {
        path: '/auth',
        name: 'AuthWithToken',
        component: AuthWithToken
    },
    // Not found route
    {
        path: '*',
        redirect: '/'
    }
];

const router = new VueRouter({
    routes,
    mode: process.env.CORDOVA_PLATFORM ? undefined : 'history',
    base: process.env.BASE_URL,
});

router.onError(error => {
    if (/loading chunk chunk-[0-9a-z]* failed./i.test(error.message)) {
        window.location.reload()
    }
})

router.beforeEach((to, from, next) => {

    loadNamespace(to.meta.modulesRequired || []).then(function() {
        if (to.path === '/auth' || to.path === '/enter-ghost-mode') {
            store.commit('logoutUser');
        }

        //precheck expiration
        if (store.state.token && to.path !== '/lock') {
            let current_time = new Date().getTime();
            let token_expire_time = store.state.token.session_start + (store.state.token.expires_in * 1000);

            if (token_expire_time + 5 * 60 * 1000 <= current_time) {
                if (to.meta.requiresAuth) {
                    store.commit('redirectAfterLogin', to.path);
                }
                return next({name: 'Lock', query: {target: to.query.target}});
            }
        }

        if (to.path !== '/lock' && store.state.isLoggedIn && !store.state.token) {
            if (to.meta.requiresAuth) {
                store.commit('redirectAfterLogin', to.path);
            }
            return next({name: 'Lock', query: {target: to.query.target}});
        }

        // check if the route requires authentication and user is not logged in
        if (to.matched.some(route => route.meta.requiresAuth) && !store.state.isLoggedIn) {
            // redirect to login page
            if (to.meta.requiresAuth) {
                store.commit('redirectAfterLogin', to.path);
            }
            return next({name: 'Login'});
        }

        if (to.matched.some(route => route.meta.redirectDashboard) && store.state.isLoggedIn) {
            if (to.query.target === 'shop') {
                auth.redirectToShop();
            } else {
                return next({name: 'Growing:Participation'});
            }
        }

        return next();
    });
});

export const loadRouteLanguage = function(locale) {
    return loadNamespace(router.currentRoute.meta.modulesRequired || [], locale);
};

export default router